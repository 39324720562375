<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane disabled label="成长记录" name="first1"> </el-tab-pane>
        <el-tab-pane label="学员成长档案" name="first">
          <div  class="work-title bg-white">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div class="work-title-content flex aligin-items-center">
                <el-popover width="150" trigger="click">
                  <el-input
                    v-model="shoolInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="shool_list"
                    v-for="(item, index) in shoolList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>校区</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <div class="cursor" @click="openDate">
                  <span>创建时间</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="archivesInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="fileTypeList"
                    v-for="(item, index) in fileType"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>档案类型 </span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>
              <div class="work-title-search">
                <el-input
                  placeholder="请输入学员姓名"
                  v-model="stuInput"
                  class="input-with-select"
                  size="mini"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
                <div
                  class="cursor text-blue margin-top-sm flex justify-content-flexEnd"
                  @click="clearFilter"
                >
                  清空筛选
                </div>
              </div>
            </div>

            <div
              class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap"
            >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div
                  class="text-gray flex aligin-items-center flex-wrap-wrap margin-top-sm"
                >
                  <div>创建时间：</div>
                  <!-- 日期组件 -->
                  <el-date-picker
                    ref="datePicker"
                    v-model="value1"
                    type="daterange" 
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                </div>
                <div
                  v-if="shool_list.length"
                  class="text-gray flex aligin-items-center margin-left-lg flex-wrap-wrap margin-top-sm"
                >
                  <div>校区：</div>
                  <div v-for="(btn, btnIndex) in shool_list" :key="btnIndex">
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="delOderStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="fileTypeList.length"
                  class="text-gray flex aligin-items-center flex-wrap-wrap margin-top-sm">
                  <div>档案类型：</div>
                  <div
                    v-for="(btn, btnIndex) in fileTypeList"
                    :key="btnIndex"
                  >
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="fileTypeStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                 
                 
              </div>
            </div>
          <div class="margin-top-sm">
            <el-button
              type="primary"
               @click="editHandle(4)"
              >新建成长档案</el-button
            >
          </div>
          <div style="margin-top: 10px">共计{{datalist.length}}条记录</div>
          <div style="margin-top: 10px">
            <as-list :initdataList="datalist">
              <el-table-column
                align="center"
                prop="dangan"
                label="档案类型"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="学员姓名"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name1"
                label="校区"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text3"
                label="老师"
              ></el-table-column>
              <el-table-column
                align="center"
                width="250"
                prop="text5"
                label="内容"
              ></el-table-column>
            
              <el-table-column
                align="center"
                prop="text7"
                label="创建时间"
              ></el-table-column>
              <el-table-column align="center" label="操作">
                <span class="text-blue cursor">删除</span>
              </el-table-column>
            </as-list>
          </div>
          </div>
          
        </el-tab-pane>
      </el-tabs>
    </div>
    <addGrowthRecord
      :Visible="editVisible && editType === 4"
      @closepop="closeEditPop"
    ></addGrowthRecord>
  </div>
</template>
<script> 
import { fileType} from '@/config/index';
import addGrowthRecord from "../components/addGrowthRecord.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    addGrowthRecord,
  },
  data() {
    return { 
      archivesInput:"",//档案类型
      shoolInput:"",//校区
      fileType,
      stuInput: "",
      teachersInput: "",
      editType: 1, 
      shool_list: [], //校区 
      fileTypeList: [], //布置教师
      datalist:[],
      datalist: [
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          dangan: "档案",
          name: "万豪_艺术区高二",
          name1: "校区",
          text3: "周老师",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
      ],

      options1: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],

      options: [
        {
          value: "校区一",
          label: "校区一",
        },
        {
          value: "校区二",
          label: "校区二",
        },
        {
          value: "校区三",
          label: "校区三",
        },
      ], //校区
      value: "",
      activeName: "first",
      value1: "",
      value2: "",
    };
  },
  methods: {
    closeEditPop(){
      this.editVisible=false;
    },
    job_List(t){
      if(t){ 
      this.jobList=true
      }else{ 
      this.jobList=false
      }
    },
    clearFilter() {
     this.fileTypeList=[];
     this.shool_list=[];
     this.stuInput='';
     this.value1='';
    },
    openDate() {
      this.$refs.datePicker.pickerVisible = true;
    },
    //档案类型
    fileTypeStatus(index) {
      this.fileTypeList.splice(index, 1);
    }, 
    delOderStatus(index) {
      this.shool_list.splice(index, 1);
    },
    JumpUrl(url) {
      this.$router.push(url);
    },
    handleClick(row) {
      console.log(row);
    },
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
  },
};
</script>

<style lang="scss">
.color-box{
  color:#8cc5ff ;
}
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}

.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>